<template>
  <v-container>
    <div v-if="fournisseurRtc">
      Fournisseur RTC: {{ fournisseurRtc.valeur }}
      <v-btn @click="onChangeModeRtc">
        Change
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters(['fournisseurRtc']),
    },

    mounted() {
      this.initFournisseurRtc()
    },

    methods: {
      ...mapActions(['initFournisseurRtc', 'updateFournisseurRtc']),

      onChangeModeRtc() {
        const newValue = this.fournisseurRtc.valeur === 'videosdk' ? 'openvidu' : 'videosdk'
        console.log('Toggle fournisseur rtc', newValue, this.fournisseurRtc.valeur)
        this.updateFournisseurRtc(newValue)
      },
    },
  }
</script>
